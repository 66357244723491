import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { ISODateWithMillisecondsString } from '@shared/string-alias-types';
import { FunctionPipe } from '@shared/pipes/function.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { HistoryItemComponent } from './history-item/history-item.component';
import { MatIcon } from '@angular/material/icon';
import { NgFor, NgStyle, NgIf } from '@angular/common';

export type HistoryItem = {
  timestamp: ISODateWithMillisecondsString;
  userId: string;
  userName: string;
  companyName?: string;
};

export type HistoryList = HistoryItem[];

@Component({
  selector: 'nxt-history-list',
  templateUrl: './history-list.component.html',
  styleUrl: './history-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, MatIcon, NgStyle, NgIf, HistoryItemComponent, TranslateModule, FunctionPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HistoryListComponent {
  @Input() historyList!: HistoryList | null;
  @Input() historyItemLabel = '';
  @Input() iconSize = '32px';
  @Input() hideCompanyName = false;
  @Input() timestampFormat = 'dd.MM.YYYY HH:mm:ss.SSS';
  @Input() firstLastAbbreviated = false;
  @Input() abbreviationLabel = '';
  @Input() totalDownloadCount?: number;

  isCenter = (index: number) => {
    if (this.historyList!.length % 2 === 0) {
      return this.historyList!.length / 2 === index + 1;
    } else {
      // Cutoff list in half with fewer items in first half in odd totalDownloads case.
      // Currently, BE can only provide first/last 25, so abbreviated list will have an even totalDownloads count, hence doesn't matter right now.
      // In case this changes in the future, the FE implementation needs to be adjusted to the BE behavior. ( ...=== index + 1.5 OR ...=== index + 0.5 )
      return this.historyList!.length / 2 === index + 1.5;
    }
  };

  getIconStyling = (iconSize: string) => ({ 'font-size': iconSize, width: iconSize, height: iconSize });

  getTextStyling = (iconSize: string) => ({ 'padding-top': this.getParsedIconSize(iconSize) / 3 - 5 + 'px' });

  getSpacerStyling = (iconSize: string) => ({ 'margin-left': this.getParsedIconSize(iconSize) / 2 - 1 + 'px' });

  private getParsedIconSize = (iconSize: string) => parseInt(iconSize, 10);
}
