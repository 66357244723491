import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'nxt-history-item',
  template: `
    <div class="row">
      <div class="history-timestamp col-auto">
        <ng-content select="nxt-history-timestamp-label"></ng-content>
        {{ timestamp | date: timestampFormat }}
      </div>
      <div class="history-content col d-flex">
        <ng-content select="nxt-history-content"></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      .history-content {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }

      .history-timestamp {
        line-height: 20px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DatePipe],
})
export class HistoryItemComponent {
  @Input() timestamp?: string;
  @Input() timestampFormat = 'dd.MM.YYYY HH:mm:ss.SSS';
}
