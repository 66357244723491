<ng-container *ngFor="let historyListItem of historyList; let last = last; let index = index;">
    <div class="history-list-container__item">
        <div class="history-list-container__item__icon-wrapper">
            <div class="col-auto">
                <mat-icon
                    class="nxt-success-status"
                    [ngStyle]="iconSize | nxtFunction:getIconStyling"
                    [attr.aria-label]="'COMMON.AXA.ICON.HISTORY.CHECK'| translate"
                    [attr.aria-hidden]="null">
                        done
                </mat-icon>
                <div
                    class="vertical-line nxt-accent-bg-400"
                    [ngStyle]="iconSize | nxtFunction:getSpacerStyling"
                    *ngIf="!last && !(firstLastAbbreviated && (index | nxtFunction:isCenter))" >
                </div>
            </div>
        </div>
        <div [ngStyle]="iconSize | nxtFunction:getTextStyling">
            <nxt-history-item
                [timestamp]="historyListItem.timestamp"
                [attr.data-test-id]="'history-list-item-' + historyListItem.timestamp">
                    <nxt-history-timestamp-label>{{ historyItemLabel }}</nxt-history-timestamp-label>
                    <nxt-history-content>
                        <ng-container *ngIf="!hideCompanyName">
                          @if(historyListItem.companyName) {
                            <span [attr.data-test-id]="'history-list-item-company-name-' + historyListItem.timestamp">{{ historyListItem.companyName }}</span>
                          }
                          @else {
                            <span [attr.data-test-id]="'history-list-item-company-name-' + historyListItem.timestamp">{{'COMMON.DELETED.LABEL.COMPANY' | translate }}</span>
                          }
                          <br>
                        </ng-container>
                        {{ 'COMMON.HISTORY_LIST.USER_ID_LABEL'| translate }} {{ historyListItem.userId }}
                        <br>
                        @if(historyListItem.userName) {
                          {{ historyListItem.userName }}
                        }
                        @else {
                          {{'COMMON.DELETED.LABEL.USER' | translate }}
                        }
                    </nxt-history-content>
            </nxt-history-item>
        </div>
    </div>
    <div
        *ngIf="firstLastAbbreviated && (index | nxtFunction:isCenter)"
        class="history-list-abbreviation-container"
        data-test-id="abbreviation-item">
        <mat-icon
            [ngStyle]="iconSize | nxtFunction:getIconStyling"
            [attr.aria-label]="'COMMON.AXA.ICON.HISTORY.MORE'| translate"
            [attr.aria-hidden]="null">
                more_vert
        </mat-icon>
        <div [ngStyle]="iconSize | nxtFunction:getTextStyling">
            <nxt-history-item>
                <nxt-history-timestamp-label>
                    <span *ngIf="totalDownloadCount">({{ totalDownloadCount }})</span> {{ abbreviationLabel }}
                </nxt-history-timestamp-label>
            </nxt-history-item>
        </div>
    </div>
</ng-container>
